.realtimereport__data__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
}
.realtimereport__data__card {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.219);
}
.realtimereport__data__card p {
  font-size: 0.9rem;
  font-weight: 500;
  color: #141414;
  text-transform: capitalize;
}
.realtimereport__data__card span {
  font-size: 2.2rem;
  color: #141414;
  font-weight: 600;
}
.realtimereport__data__cardbtn {
  background-color: rgba(65, 105, 225, 0.911);
  border: 1px solid royalblue;
  cursor: pointer;
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.2s ease;
}
.realtimereport__data__cardbtn__icon {
  font-size: 1.2rem;
  margin-right: 5px;
}
.realtimereport__data__cardbtn:hover {
  background-color: rgb(39, 70, 163);
  transition: 0.1s ease;
}

@media screen and (max-width: 1050px) {
  .realtimereport__data__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .realtimereport__data__wrapper {
    display: flex;
    flex-direction: column;
  }
}
