.f__pass {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f__pass__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.f__pass__wrapper__icon__wrapper {
  background-color: rgba(65, 105, 225, 0.11);
  padding: 10px;
  height: max-content;
  width: max-content;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f__pass__wrapper__icon {
  padding: 10px;
  font-size: 3.5rem;
  color: #141414;
  background-color: rgba(65, 105, 225, 0.274);
  border-radius: 50%;
}

.f__pass__wrapper h1 {
  font-weight: 600;
  color: #141414;
  margin: 2rem 0 0.2rem 0;
}

.f__pass__wrapper p {
  font-weight: 500;
  color: #8a8a8a;
}

.f__pass__form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
  margin: 2rem 0;
}

.f__pass__form label {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.f__pass__form__input {
  margin-bottom: 2rem;
  padding: 10px;
  width: 100%;
  border: 1px solid #969696;
  border-radius: 10px;
  outline: none;
}
.f__pass__form__btn {
  padding: 10px;
  width: 100%;
  background-color: royalblue;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.f__pass__goback__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  color: royalblue;
}
.f__pass__goback__link__icon {
  margin-right: 10px;
}
