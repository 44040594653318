
.workflow {
    height: 100vh;
    width: 100%;
    padding: 5rem 3rem 3rem 3rem;
  }

  .dropdown {
    font-size: 3rem;
  }


  .workflow__popup__content {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    position: relative;
  }

  .workflowpopup textarea {
    width: 80%;
    margin-top: 1rem;
    height: 100px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #dfdfdf;
    background-color: #f5f4f4;
    border-radius: 5px;
    resize: none;
    outline: none;
  }

  /*saurav*/

  .input-event{
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding-left: 10px;
    font-size: 1rem;
  }

  .date-time{
    display:flex;
    margin-top: 5px;
  }

  .start-date-input{
    width: 235px;
    padding: 8px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

  .end-date-input{
    width: 243px;
    padding: 8px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

  .time-to-respond{
    width: 184px;
    padding: 8px;
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

  .time-to-action{
    width: 200px;
    padding: 8px;
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

  .assignedfake{
    visibility: hidden;
  }


  .actioneye{
    font-size: 20px;
    color: black;
    margin-left: 5px;
  }


  /* .workflow/workflows-post/  */
  /*  workflow/getworkflows/ */


  .workflow-update{
    margin-top: 5rem;
    margin-left: 200px;
    }


/* datagrid column ID hide  */
.MuiDataGrid-colCell-id {
  display: none;
}
/* datagrid column ID hide  */


.workflow-update-btn{
    padding: 5px 15px;
    font-size: 1rem;
    border: none;
    background-color: rgba(65, 105, 225, 0.253);
    color: royalblue;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    margin-bottom: 2rem;
}



.workflow__popup__submitBtn {
    padding: 20px 30px;
    font-size: 1rem;
    border: none;
    background-color: rgba(65, 105, 225, 0.253);
    color: royalblue;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
}


.workflow-update-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust minmax values as needed */
  gap: 20px;
  margin-top: 20px; /* Adjust gap value as needed */
}

.update-label {
  font-weight: bold;
}

.update-input,
.ant-select-selector {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.workflow-disable-inputs {
  grid-column: span 2; /* This will span two columns */
}

.updating-inputs {
  grid-column: span 2; /* This will span two columns */
}

.workflow-update-btn {
  grid-column: span 2; /* This will span two columns */
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.workflow-update-btn:hover {
  background-color: #0056b3;
}

.status-input{
  width: 100%;
    padding: 0px;
    box-sizing: border-box
}

.updatearrow{
  position: relative;
  top: 5px;
}

.updatebackbtn{
  text-align: center;
  padding: 0px 10px 5px 10px;
  position: absolute;
  right: 0;
  margin-right: 30px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background-color: rgba(65, 105, 225, 0.199);
  color: royalblue;
  font-weight: 500;
}

.parameter-option{
  font-size: 1px;
}

.selectall{
  cursor: not-allowed;
}

.parameter-checkbox{
  cursor: pointer;
}


@media screen and (max-width: 1240px){

  .workflow__popup__content {
    width: 80%;
    display: flex;
   flex-wrap: wrap;
    position: relative;
  }
  
  
  .workflow-update{
    margin-top: 5rem;
    margin-left: 20px;
    }
  
  }


@media screen and (max-width: 720px){

.workflow__popup__content {
  width: 80%;
  display: flex;
 flex-wrap: wrap;
  position: relative;
}

.workflow-update{
  margin-top: 5rem;
  margin-left: 20px;
  }

}

@media screen and (max-width: 480px){

  .workflow__popup__content {
    width: 40%;
    display: flex;
   flex-wrap: wrap;
    position: relative;
  }
  
  .workflow-update{
    margin-top: 5rem;
    margin-left: 10px;
    }
  
  }


  @media screen and (max-width: 360px){

    .workflow__popup__content {
      width: 40%;
      display: flex;
     flex-wrap: wrap;
      position: relative;
    }
    
    .workflow-update{
      margin-top: 5rem;
      margin-left: 10px;
      }
    
    }