.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: red;
    padding: 50px;
    font-size: 20px;
    border: 1px solid #ccc;
    color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }

  .popup-content {
    text-align: center;
  }

  .popup-message{
    margin-bottom: 5px;
  }

  .subscription-ok-button{
    padding: 5px 40px;
    font-size: 18px;
    cursor: pointer;
  }

  .subscription-icon{
    font-size: 28px;
    position: relative;
    top: 6px;
  }
   




