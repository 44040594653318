
 @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
 ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
 ::-webkit-scrollbar-thumb {
  background: #23395d;
  border-radius: 10px;
}

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
  background: #23395d;
}

 html,
 body,
 #root {
  height: 100%;
  width: 100%;
 }

 .content {
  display: flex;
  justify-content: flex-end;
}

