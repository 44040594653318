.sitestatus {
  padding: 5rem 1rem 0 1rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  z-index: 99;
  overflow: hidden;
}
.sitestatus__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sitestatus__header h1 {
  font-weight: 600;
  color: #141414;
}
.sitestatus__header__searchbar{
  z-index: 0;
  width: 30%;
}
.sitestatus__tablewrapper {
  margin-top: 1rem;
}
.sitestatus__table__btn {
  border: none;
  background-color: rgba(65, 105, 225, 0.74);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}
.sitestatus__title {
  font-size: 1.6rem;
  color: #141414;
  font-weight: 600;
}

.global__search__exportbtn {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: royalblue;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.global__search__exportbtn__icon {
  font-size: 1.4rem;
  color: #fff;
}

.global__search {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.global__search__wrapper {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.global__search__input {
  width: 90%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  font-size: 0.9rem;
  padding-left: 3rem;
  border: none;
  background-color: #ffffff;
}
.global__search__icon {
  position: absolute;
  top: 33%;
  left: 12%;
  font-size: 1.3rem;
  color: rgb(129, 126, 126);
  z-index: 1;
}

.sitestatus__table__wrapper {
  height: 450px;
  overflow: scroll;
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
}
.sitestatus__table {
  border-collapse: collapse;
  width: 100%;
  position: relative;
}

.sitestatus__table__header {
  position: sticky;
  top: 0;
  background-color: white;
  border: 1px solid #1414143f;
}
.sitestatus__table__header th {
  padding: 8px;
}
.sitestatus__table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #1414143b;
}
.sitestatus__table th {
  background-color: #f2f2f2;
}
/* .sitestatus__table tr:nth-child(even) {
} */
.sitestatus__table td button {
  background-color: royalblue;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.realtimereport__livereading {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.realtimereport__livereading__container {
  overflow: scroll;
  width: 100%;
  height: 300px;
}

.realtimereport__livereading__card {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.185);
  border-radius: 3px;
}
.realtimereport__livereading__card__icon {
  margin-right: 10px;
  font-size: 3rem;
  color: royalblue;
}

.realtimereport__livereading__card__content__parameterName {
  color: royalblue;
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}
.realtimereport__livereading__card__content p {
  font-size: 1rem;
  font-weight: 500;
}
.realtimereport__livereading__card__content p span {
  color: green;
  font-size: 2.2rem;
  font-weight: 500;
}

.liveReadings {
  font-size: 1.2rem;
  color: #141414;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Site Status Data */

.sitestatusdata {
  padding: 5rem 1rem 0 1rem;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: scroll;
}

.sitestatusdata__header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.sitestatusdata__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: max-content;
}
.sitestatusdata__header h1 {
  font-size: 2.1rem;
  font-weight: 550;
  color: #141414;
}
.sitestatusdata__header__location__cat__wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
}


.sitestatusdata__header__content__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: rgba(65, 105, 225, 0.205);
  padding: 5px;
  font-size: 0.9rem;
  border-radius: 3px;
}
.sitestatusdata__header__content__btn__icon {
  margin-right: 5px;
  font-size: 1rem;
  transition: 0.2s ease;
}

.sitestatusdata__header__content__btn:hover
  .sitestatusdata__header__content__btn__icon {
  margin-right: 15px;
}
.sitestatusdatalocation__type__icon {
  font-size: 0.9rem;
  margin-right: 5px;
}

.sitestatusdata__container {
  background-color: #fff;
  border-radius: 1rem;
  padding: 10px;
  margin-top: 10px;
}

.sitestatusdatalocation__type__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.9;
  background-color: royalblue;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  color: #fff;
}
.sitestatusdatalocation__type__btn__icon {
  font-size: 1rem;
  color: #fff;
  margin-right: 5px;
}

.test {
  display: flex;
  flex-direction: column;

}
.expired-row {

  color: #d32f2f !important; /* Deep red text */
  font-weight: bold;
}



@media screen and (max-width: 1050px) {
  .realtimereport__livereading {
    grid-template-columns: repeat(2, 1fr);
  }
  .sitestatusdata__header {
    flex-direction: column;
  }

  .realtimereport__livereading__card {
    background-color: #fff;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 300px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.185);
    border-radius: 2px;
  }
}

@media screen and (max-width: 550px) {
  .realtimereport__livereading {
    grid-template-columns: 1fr;
  }
}
