/* .login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
}

.login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./Images/BackgroundImg.jpg);
  background-size: cover;
  filter: brightness(0.9);
  z-index: -1;
}

.login__wrapper {
  display: flex;
  width: 70%;
  height: 90%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.452);
}

.login__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 60%;
  user-select: none;
  background-color: #fff;
}

.login__container__wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.login__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  margin-bottom: 1rem;
  z-index: 1;
}

.login__logo__img1 {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.login__logo__img2 {
  filter: brightness(1.7);
  height: 70px;
  margin-top: 1rem;
}

.login__form {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 1rem 0;
  width: 80%;
  position: relative;
}

.login__container__wrapper span {
  width: 80%;
  font-size: 0.9rem;
  color: rgb(88, 87, 87);
  margin-bottom: 5px;
  font-weight: 500;
}

.login__container__wrapper h1 {
  font-weight: 600;
  color: #141414;
  font-size: 1.5rem;
  width: 80%;
}

.login__form input {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  padding: 1rem;
  outline: none;
  border: none;
  font-weight: 500;
  background-color: #ecebebab;
  border-radius: 5px;
  transition: 0.2s ease;
}

.incorrect {
  background-color: rgba(255, 0, 0, 0.164) !important;
  animation: shake 0.3s linear;
}

.showpass {
  position: absolute;
  bottom: 43%;
  right: 5%;
  font-size: 1.2rem;
  color: #3b3b3b;
  cursor: pointer;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.login__form input:hover {
  background-color: rgba(65, 105, 225, 0.171);
  transition: 0.2s ease;
}
.login__form input:focus {
  background-color: rgba(65, 105, 225, 0.24);
  transition: 0.2s ease;
}

.loginBtn {
  padding: 10px;
  color: #fff;
  font-size: 1rem;
  background-color: #353839;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
  text-align: center;
  text-decoration: none;
}
.f_pass__link {
  font-size: 0.9rem;
  color: royalblue;
  text-decoration: none;
  font-weight: 500;
  width: 80%;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  transition: 0.2s all ease;
}
.f_pass__link:hover {
  background-color: rgba(65, 105, 225, 0.116);
}

.login__container__footer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login__container__footer span {
  font-size: 0.8rem;
  font-weight: 500;
  color: #636262;
}

.login__rightside__container {
  height: 100%;
  width: 40%;
  backdrop-filter: brightness(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login__rightside__container__bgImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  object-fit: cover;
  filter: brightness(0.5);
}

.login__form__message {
  color: crimson;
  font-weight: 500;
  text-transform: capitalize;
  background-color: rgba(255, 0, 0, 0.26);
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 5px;
  position: absolute;
  top: -17%;
  left: 0;
}

.loginLoader {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.loginLoader__wrapper {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loginLoader__wrapper h1 {
  font-weight: 600;
  font-size: 2.5rem;
  color: #141414;
}
.loginLoader__wrapper p {
  font-weight: 500;
  font-size: 1.3rem;
  color: #444444;
}
.loginLoader__wrapper video {
  height: 350px;
}




@media screen and (max-width: 1200px) {
  .login__wrapper {
    width: 90%;
  }

  .login__container {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 1000px) {
  .login__wrapper {
    width: 90%;
    height: max-content;
  }
  .login__rightside__container {
    display: none;
  }
  .login__container {
    width: 100%;
  }
  .login__form {
    width: 90%;
  }
  .login__container__wrapper h1 {
    width: 90%;
  }
  .login__container__wrapper span {
    width: 90%;
  }
  .login__logo__img1 {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }

  .login__logo__img2 {
    height: 50px;
  }
}
 */


 /* General Styling for Login */
.login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  z-index: 999999;
}

.login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./Images/BackgroundImg.jpg);
  background-size: cover;
  filter: brightness(0.9);
  z-index: -1;
}

.login__wrapper {
  display: flex;
  width: 70%;
  height: 90%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.452);
}

.login__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 60%;
  user-select: none;
  background-color: #fff;
}

.login__container__wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.login__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  margin-bottom: 1rem;
  z-index: 1;
}

.login__logo__img1 {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.login__logo__img2 {
  filter: brightness(1.7);
  height: 70px;
  margin-top: 1rem;
}

/* Login Form Styling */
.login__form {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 1rem 0;
  width: 80%;
  position: relative;
}

.login__container__wrapper span {
  width: 80%;
  font-size: 0.9rem;
  color: rgb(88, 87, 87);
  margin-bottom: 5px;
  font-weight: 500;
}

.login__container__wrapper h1 {
  font-weight: 600;
  color: #141414;
  font-size: 1.5rem;
  width: 80%;
}

.login__form input {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  padding: 1rem;
  outline: none;
  border: none;
  font-weight: 500;
  background-color: #ecebebab;
  border-radius: 5px;
  transition: 0.2s ease;
}

.incorrect {
  background-color: rgba(255, 0, 0, 0.164) !important;
  animation: shake 0.3s linear;
}

.showpass {
  position: absolute;
  bottom: 43%;
  right: 5%;
  font-size: 1.2rem;
  color: #3b3b3b;
  cursor: pointer;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.login__form input:hover {
  background-color: rgba(65, 105, 225, 0.171);
  transition: 0.2s ease;
}

.login__form input:focus {
  background-color: rgba(65, 105, 225, 0.24);
  transition: 0.2s ease;
}

.loginBtn {
  padding: 10px;
  color: #fff;
  font-size: 1rem;
  background-color: #353839;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
  text-align: center;
  text-decoration: none;
}

.f_pass__link {
  font-size: 0.9rem;
  color: royalblue;
  text-decoration: none;
  font-weight: 500;
  width: 80%;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  transition: 0.2s all ease;
}

.f_pass__link:hover {
  background-color: rgba(65, 105, 225, 0.116);
}

/* Footer Section */
.login__container__footer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login__container__footer span {
  font-size: 0.8rem;
  font-weight: 500;
  color: #636262;
}

/* Right Side Container */
.login__rightside__container {
  height: 100%;
  width: 40%;
  backdrop-filter: brightness(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login__rightside__container__bgImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  object-fit: cover;
  filter: brightness(0.5);
}

/* Login Form Message */
.login__form__message {
  color: crimson;
  font-weight: 500;
  text-transform: capitalize;
  background-color: rgba(255, 0, 0, 0.26);
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 5px;
  position: absolute;
  top: -17%;
  left: 0;
}

/* Loader */
.loginLoader {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.loginLoader__wrapper {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginLoader__wrapper h1 {
  font-weight: 600;
  font-size: 2.5rem;
  color: #141414;
}

.loginLoader__wrapper p {
  font-weight: 500;
  font-size: 1.3rem;
  color: #444444;
}

.loginLoader__wrapper video {
  height: 350px;
}
