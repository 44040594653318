

.camera-stream {
  padding: 5rem 1rem 0 1rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  z-index: 99;
}

.camerapage{
  margin-top: 4rem;
}

/* 
.css-1e2bxag-MuiDataGrid-root {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
} */


.data-grid-table{
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.videocamericon{
  cursor: pointer;
  color: black;
  margin-left: 10px;
}

.tabpanel{
  position: absolute;
  top: 100px;
  right: 30px
}

.ptz-control-buttons {
  display:-webkit-box;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; 
  background-color: lightgrey;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  margin-left: 20px;
}

.ptz-control-buttons button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  /* Add more styling as needed */
}

.zoom{
  font-size: 20px;
}

.ptz-button {
  position: relative;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;  /* Add animation to the transform and background-color properties */
}

.ptz-button span {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
}


.ptz-button:hover {
  transform: scale(1.1);  /* Scale the button on hover */
 /* Change the background color on hover */
}


.ptz-button:hover span {
  display: block;
}



.site-details{
  display: flex;
  gap: 30px;
}

.flow-paragraph{
  margin-bottom: 0px;
}


.history{
display: inline-flex;
}


.history-label{
  margin-left: 20px;
}


.historydateinput{
  display: block;
  margin-top: 5px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}


.history-fetch-btn{
  position: absolute;
  top: 140px;
  left: 460px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: royalblue;
  color: white;
}

.camerabackbtn{
  position: absolute;
  right: 0;
  margin-right: 70px;
  align-items: center;
  padding: 2px 15px;
  border: none;
  border-radius: 3px;
  background-color: rgba(65, 105, 225, 0.199);
  color: royalblue;
  font-weight: 500;
  cursor: pointer;
}

.backbtnicon{
 position: relative;
 top: 5px;
 font-size: 20px;
}

iframe{
width: 730px;
height: 380px;
border: 1px solid black;
}


.googlemap{
  border: 10px;
}



.industry-details table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.industry-details th,
.industry-details td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.industry-details th {
  background-color: #f2f2f2;
}

.camera-details-table {

}

.camera-details-table table {
  width: 100%;
  border-collapse: collapse;
}

.camera-details-table th,
.camera-details-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.camera-details-table th {
  background-color: #f2f2f2;
}


.details-slider {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.slider-header {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 10px;
}

.slider-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.details-container {
  padding: 20px;
}

.industry-details,
.camera-details {

}

.industry-details.active,
.camera-details.active {
  display: block;
}



.ptz-speed-dropdown{
  display: inline-grid;
  position: relative;
  bottom: 20px;
  left: 30px;
}

.select-speed{
  margin-top: 20px;
  padding: 5px 10px;
}

/*responsive  */

@media screen and (max-width: 1080px) {
  .camera-stream {
    padding: 2rem 1rem;
  }
  
  /* Move the table panel below the camera */
  .tabpanel {
    position: static;
    margin-top: 1rem;
    
  }

  .site-details{
    display: flex;
    flex-wrap: wrap;
  }
}


@media screen and (max-width: 720px) {
  .camera-stream {
    padding: 2rem 1rem;
  }

  .camera-frame {
    position: relative;
    width: 100%; /* Set width to 100% of its container */
  }


  .gm-style {
    width: 300px;;
  }

  

  /* Move the table panel below the camera */
  .tabpanel {
    position: static;
    margin-top: 1rem;
  }

  .site-details{
    display: flex;
    flex-wrap: wrap;
  }

  .history{
    display:flex;
    flex-wrap: wrap;
    width: 50%;
    }
    
    .history-fetch-btn{
      position: absolute;
      top: 260px;
      left: 20px;
      padding: 5px 20px;
      border-radius: 5px;
      background-color: royalblue;
      color: white;
    }

    .camerabackbtn{
      position: absolute;
      top: 30px;
     left: 0;
      align-items: center;
      padding: 2px 15px;
      border: none;
      border-radius: 3px;
      background-color: rgba(65, 105, 225, 0.199);
      color: royalblue;
      font-weight: 500;
      cursor: pointer;
      z-index:2;
    }
    
    .backbtnicon{
     position: relative;
     top: 5px;
     font-size: 20px;
    }
    
}
/* 

@media screen and (max-width: 480px) {
  .camera-stream {
    padding: 1rem;
  }

  .camera-flowchart{
    height: 250px;
    min-width: 200px
  }

  .tabpanel {
    position: static;
    margin-top: 1rem;
  }

  .site-details{
    display: flex;
    flex-wrap: wrap;
  }
  
  .history{
    display:flex;
    flex-wrap: wrap;
    width: 50%;
    }
    
    .history-fetch-btn{
      position: absolute;
      top: 260px;
      left: 20px;
      padding: 5px 20px;
      border-radius: 5px;
      background-color: royalblue;
      color: white;
    }

    .camerabackbtn{
      position: absolute;
      right: 0;
      align-items: center;
      padding: 2px 15px;
      border: none;
      border-radius: 3px;
      background-color: rgba(65, 105, 225, 0.199);
      color: royalblue;
      font-weight: 500;
      cursor: pointer;
    }
    
    .backbtnicon{
     position: relative;
     top: 5px;
     font-size: 20px;
    }
}*/


@media screen and (max-width: 360px) {
  .history-fetch-btn{
    position: absolute;
    top: 290px;
    left: 20px;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: royalblue;
    color: white;
  }
} 