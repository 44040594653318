.cpcb-report-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin-top: 100px;
}

.cpcb-report-container h2 {
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 2.2rem;
}

.report-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.cpcb-report-options {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
  margin-bottom: 20px;
}

.cpcb-report-input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cpcb-report-label {
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 8px;
  font-weight: bold;
}

.report {
  background-color: #f8f9fa;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cpcb-reports-buttons{
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.report:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.report h3 {
  color: #34495e;
  margin-bottom: 10px;
  font-size: 1.6rem;
}

.report p {
  color: #7f8c8d;
  font-size: 1rem;
  margin-bottom: 20px;
}

.download-btn {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .report {
      width: 100%;
  }
  
  .cpcb-report-options {
      flex-direction: column;
      align-items: stretch;
  }
}
