.remote-calibration{
     height: 100%;
     width: 100%;
     padding: 5rem;
}

.add-icon{
   font-size: 2em;
}

.remotecal {
     height: 100vh;
     width: 100%;
     padding: 5rem 3rem 3rem 3rem;
   }
   .dropdown {
     font-size: 3rem;
   }
   
   .remotecal__header {
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 1rem;
   }
   .remotecal__header button {
     height: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 5px;
     border: none;
     border-radius: 3px;
     background-color: rgba(65, 105, 225, 0.199);
     color: royalblue;
     font-weight: 500;
     cursor: pointer;
   }
   
   .remotecalpopup {
     position: absolute;
     top: 0;
     left: 0;
     height: 100vh;
     width: 100%;
     background-color: #fff;
   }
   
   .remotecalpopup {
     padding: 5rem 3rem 3rem 3rem;
     width: 100%;
   }
   .remotecal__popup__content {
    margin-top: 2rem;
     width: 90%;
     display: grid;
     grid-template-columns: repeat(4, 1fr);
     gap: 10px;
     position: relative;
     
   }
   
   .remotecalpopup textarea {
     width: 80%;
     margin-top: 1rem;
     height: 200px;
     padding: 10px;
     font-size: 1rem;
     border: 1px solid #dfdfdf;
     background-color: #f5f4f4;
     border-radius: 5px;
     resize: none;
     outline: none;
   }
   .remotecalpopup textarea:hover {
     border: 1px solid royalblue;
     transition: 0.2s ease-in all;
   }
   .remotecalpopup textarea:focus {
     border: 1px solid royalblue;
   }
   
   .remotecal__popup__submitBtn {
     display: block;
     padding: 10px;
     font-size: 1rem;
     border: none;
     background-color: rgba(65, 105, 225, 0.253);
     color: royalblue;
     font-weight: 500;
     border-radius: 5px;
     cursor: pointer;
     display: flex;
     justify-content: center;
     align-items: center;
     width: max-content;
   }
   .remotecal__popup__submitBtn__icon {
     font-size: 1.2rem;
     color: royalblue;
     margin-left: 10px;
   }
   
   .remotecal__popup__submitBtn:hover {
     background-color: rgba(65, 105, 225, 0.397);
     transition: 0.1s all;
   }
   .remotecal__header__btn__icon {
     font-size: 1.1rem;
     margin-right: 5px;
   }
   
   /* Not Avaiable Styling  */
   .remotecal__NA {
     height: 100vh;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
   }
   .remotecal__NA video {
     height: 200px;
     margin-bottom: 2%;
   }
   .remotecal__NA h1 {
     font-size: 1rem;
     text-transform: capitalize;
     font-weight: 500;
     color: #5e5e5e;
   }
   
   /*saurav*/

   .remotecal-label{
    display: block;
   }
   
   .remotecal-input{
     display: inline-block;
     width: 560px;
     height: 40px;
     border-radius: 5px;
     border: 1px solid #d9d9d9;
     padding-left: 10px;
     font-size: 1rem;
     margin-bottom: 5px;
   }
   
   .calibration-datetime{
    display: inline;
    margin-bottom: 20px;
   }
   
   .start-date-input{
     width: 235px;
     padding: 8px;
     margin-left: 10px;
     border-radius: 5px;
     border: 1px solid #d9d9d9;
   }
   
   .end-date-input{
     width: 243px;
     padding: 8px;
     margin-left: 10px;
     border-radius: 5px;
     border: 1px solid #d9d9d9;
   }
   
   .time-to-respond{
     width: 184px;
     padding: 8px;
     margin-left: 5px;
     border-radius: 5px;
     border: 1px solid #d9d9d9;
   }
   
   .schedule-day{
     width: 200px;
     margin-left: 5px;
     border: none;
   }
   
   .assignedfake{
     visibility: hidden;
   }


   .calibration-label{
    margin-left: 20px;
   }


   .calibration-radio{
    margin-top: 10px;
    margin-bottom: 10px;
   }



   .externalcal-radio{
    margin-left: 20px;
    font-size: 14px;
   }

   .externalcal-input{
    margin-right: 8px;
   }

  
   
   


