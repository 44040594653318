.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000083;
  z-index: 9999;
}

.loader__container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  pointer-events: none;
}

.loader__container__img {
  animation: animName 2s linear infinite;
  height: 70px;
  width: 70px;
}

@keyframes animName {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
