.account__popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #14141423;
  z-index: 9999;
}

.account__popup__wrapper {
  background-color: #faf9f9;
  height: 60%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.205);
  text-align: center;
}
.account__popup__wrapper h1 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.account__popup__icon {
  font-size: 5rem;
  margin-bottom: 3rem;
  color: #23395d;
}

.account__popup__btn__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account__popup__btn {
  width: 80%;
  font-size: 1.1rem;
  padding: 5px 0;
  background-color: #e9e7e7;
  text-align: center;
  color: #131313;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 10px;
  border: none;
}
.account__popup__btn.signout {
  background-color: #353839;
  color: #fff;
  border: none;
}

@media screen and (max-width : 900px) {
  .account__popup__wrapper{
    width: 50%;
  }
}